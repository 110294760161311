/*
 * @Description: 
 * @Author: wangxiaole
 * @Date: 2024-09-18 11:13:36
 * @LastEditTime: 2024-11-18 15:58:34
 * @LastEditors:  
 */

/**
 * meta.KeepAlive 表示页面留活
 * meta.permissionUrls
 *    1.[]  表示始终有权限
 *    2.null 或 undefined 无权限
 *    3. [xx,xxx] 用户登录查询返回的菜单数据，包含其中一个菜单url，就会动态添加到路由中（否则不能访问该页面）
 */
export default [
  { 
    path: 'marketcode',
    name: 'marketcode',
    component: () => import('../views/marketCode/index.vue'),
    meta: {
      permissionUrls: [],
      routerType: 2,
    }
  },
  { 
    path: 'markettypecode',
    name: 'markettypecode',
    component: () => import('../views/marketTypeCode/index.vue'),
    meta: {
      permissionUrls: [],
      routerType: 2,
    }
  },
  { 
    path: 'revenuetype',
    name: 'revenuetype',
    component: () => import('../views/revenuetype/index.vue'),
    meta: {
      permissionUrls: [],
      routerType: 2,
    }
  },
  { 
    path: 'priceCodeType',
    name: 'priceCodeType',
    component: () => import('../views/priceCodeType/index.vue'),
    meta: {
      permissionUrls: [],
      routerType: 2,
    }
  },
  { 
    path: 'roomCode',
    name: 'roomCode',
    component: () => import('../views/roomCode/index.vue'),
    meta: {
      permissionUrls: [],
      routerType: 2,
    }
  },
  { 
    path: 'roomCodeType',
    name: 'roomCodeType',
    component: () => import('../views/roomCodeType/index.vue'),
    meta: {
      permissionUrls: [],
      routerType: 2,
    }
  },
  { 
    path: 'sourceCode',
    name: 'sourceCode',
    component: () => import('../views/sourceCode/index.vue'),
    meta: {
      permissionUrls: [],
      routerType: 2,
    }
  },
  { 
    path: 'sourceCodeType',
    name: 'sourceCodeType',
    component: () => import('../views/sourceCodeType/index.vue'),
    meta: {
      permissionUrls: [],
      routerType: 2,
    }
  },
  { 
    path: 'contralSetting',
    name: 'contralSetting',
    component: () => import('../views/contralSetting/index.vue'),
    meta: {
      permissionUrls: [],
      routerType: 2,
    }
  },
  { 
    path: 'rentalRate',
    name: 'rentalRate',
    component: () => import('../views/rentalRate/index.vue'),
    meta: {
      permissionUrls: [],
      routerType: 2,
    }
  },
];
