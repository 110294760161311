/*
 * @Description: 
 * @Author: wangxiaole
 * @Date: 2024-09-26 15:48:22
 * @LastEditTime: 2024-09-26 16:36:20
 * @LastEditors:  
 */
import Router from 'vue-router';
import defaultRouter from './default-router';
const RouterView = {
  template: '<router-view></router-view>',
};
const routes = [{
  path: '/system',
  component: RouterView,
  children: [
    ...defaultRouter,
  ],
  caseSensitive: true,
}];

export default new Router({
  mode: 'history',
  routes,
});
