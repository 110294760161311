<!--
 * @Descripttion:
 * @version:
 * @Author: wangxiaole
 * @Date: 2020-02-19 14:00:54
 * @LastEditors:  
 * @LastEditTime: 2024-11-05 17:08:09
 -->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
  },
};
</script>
<style lang="less" >
.lc-pop.el-popper{
  padding: 12px !important;
  min-height: auto !important;
}
#avocado-module-front-end-hq-system-config{
  height: calc(100vh - 110px);
    width: calc(100vw - 190px);
    overflow-y: auto;
    position: relative;
    margin: -10px -15px;
}
.el-message-box__status{
  top: 48%;
}
</style>
