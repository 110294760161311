
import './set-public-path';
import Vue from 'vue';
import Vuex from 'vuex';
import AvocadoLoadVue from 'avocado-load-vue';
import packageConfig from '../package.json';
import HttpUtil from './utils/HttpUtil';
import router from './router';
import App from './App.vue';
import has from 'lodash/has';
import forEach from 'lodash/forEach';

// 添加Vuex支持
Vue.use(Vuex);
Vue.prototype.$httpUtil = HttpUtil;
// 添加全局组件
// 设置路由生命周期方法
if (has(Vue.prototype, '$routerHook')) {
  forEach(Vue.prototype.$routerHook, (item, index) => {
    router[index](item);
  });
}
// 使用Avocado创建生命周期对象
const vueLifecycles = AvocadoLoadVue({
  Vue,
  rootDomId: 'content',
  // 应用节点名称
  appName: packageConfig.name,
  appOptions: {
    render: h => h(App),
    router,
  },
});

export const { update } = vueLifecycles;
export const bootstrap = (props) =>
  vueLifecycles.bootstrap(props).then((props) => {
    if(document.getElementById('views')) {
      document.getElementById('views').style.display='none'
    }
  });
  export const mount = (props) =>
    vueLifecycles.mount(props).then((props) => {
      if(document.getElementById('views')) {
        document.getElementById('views').style.display='none'
         document.getElementById('avocado-module-front-end-hq-system-config').style.display='block'
      }
    });
  export const unmount = (props) =>
    vueLifecycles.unmount(props).then((props) => {
      if(document.getElementById('views')) {
        document.getElementById('views').style.display='block'
        document.getElementById('avocado-module-front-end-hq-system-config').style.display='none'
        
      }
    });